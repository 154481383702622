import EnvironmentConfig from '../interfaces/EnvironmentConfig';

const production: EnvironmentConfig = {
  dashboardUrl: 'https://dashboard.casa.ajira.tech',
  ruleServerUrl: 'https://api.casa.ajira.tech',
  apiServerUrl: 'https://api.customerstudio.ajira.tech',
  casaWebAppUrl: 'https://web.casa.ajira.tech',
  casaEcomUrl: 'https://ecom.casa.ajira.tech',
  casaBeatUrl: 'https://beat-api.casa.ajira.tech',
  turnedOffFeatures: [],
  keycloakConfig: {
    url: `https://auth.casa.ajira.tech/auth`,
    realm: `casa`,
    clientId: `casa_dashboard`,
  },
};

export default production;
