import axios from "axios"

import config from "../config"
import User from "../interfaces/User"
import { concat, flatMap, get, map } from "lodash"
import TenantRole from "../interfaces/TenantRole"

const { ruleServerUrl } = config

export const getUser = () => {
  return axios.get<User>(`${ruleServerUrl}/users`).then(({ data }) => data)
}

export const getUserPermissions = (user: User) => {
  let roleGroups: TenantRole[] = get(user, "tenant_role_groups", [])
  let userPerms: string[] = []

  for (let i = 0; i < roleGroups.length; i++) {
    let currPerms = get(roleGroups[i], "permissions")
    if (Array.isArray(currPerms)) {
      userPerms = concat(userPerms, currPerms)
    } else {
      userPerms = concat(
        userPerms,
        flatMap(map(Object.values(currPerms), (perm) => perm.enabled))
      )
    }
  }

  return userPerms
}

export const doesHavePermissions = (
  userPermissions: string[],
  validatePermissions: string[]
): boolean => {
  const permissionMap = userPermissions.reduce(
    (permissionMap, curr) => ({ ...permissionMap, [curr]: true }),
    {} as Record<string, boolean>
  )
  const doesHaveAllPermissions = validatePermissions.every(
    (p) => permissionMap[p]
  )
  return doesHaveAllPermissions
}
