import { Alert } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import toast from 'react-hot-toast';

import Auth from '../interfaces/Auth';
import User from '../interfaces/User';
import * as keycloak from '../keycloak';
import { getUser } from '../services/user';
import CasaLoader from '../shared/components/CasaLoader/CasaLoader';
import { UserProvider } from './User';

const AuthContext = createContext<Auth>({
  currentUser: null,
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const isAuthenticated = await keycloak.initialize();

        if (!isAuthenticated) return keycloak.redirectToLogin();

        const accessToken = keycloak.getAccessToken();

        if (!accessToken) {
          throw new Error('Authentication failed');
        }

        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${accessToken}`;
        // axios.defaults.baseURL = config.ruleServerUrl; // TODO: Need to switch everything api Server

        const user = await getUser();
        setCurrentUser(user);

        toast.success(`Welcome back, ${_.capitalize(user.tenant.displayName)}`);
      } catch (error) {
        setError((error as Error).message);
        toast.error(`Oops! Something went wrong`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isLoading) return <CasaLoader />;
  if (error) return <Alert severity='error'>{error}</Alert>;
  if (!currentUser)
    return <Alert severity='error'>Oops! Something went wrong</Alert>;

  return (
    <AuthContext.Provider value={{ currentUser }}>
      <UserProvider currentUser={currentUser}>{children}</UserProvider>
    </AuthContext.Provider>
  );
};

export default AuthContext;
