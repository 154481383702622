import EnvironmentConfig from '../interfaces/EnvironmentConfig';

const qaConfigs: EnvironmentConfig = {
  dashboardUrl: 'https://dashboard.casaqa.ajira.tech',
  ruleServerUrl: 'https://api.casaqa.ajira.tech',
  apiServerUrl: 'https://api.customerstudioqa.ajira.tech',
  casaWebAppUrl: 'https://web.casaqa.ajira.tech',
  casaEcomUrl: 'https://ecom.casaqa.ajira.tech',
  casaBeatUrl: 'https://beat-api.casaqa.ajira.tech',
  turnedOffFeatures: [],
  keycloakConfig: {
    url: `https://auth.casaqa.ajira.tech/auth`,
    realm: `casa`,
    clientId: `marketers_dashboard`,
  },
};

export default qaConfigs;
