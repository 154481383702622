import React from "react"
import ReactDOM from "react-dom/client"
import theme from "./theme"
import { CssBaseline, ThemeProvider } from "@mui/material"
import App from "./App"
import { Toaster } from "react-hot-toast"
import { AuthProvider } from "./contexts/Auth"
import * as ServiceWorkerRegistration from "./sw-registration"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <React.StrictMode>
      <App />
      </React.StrictMode>
    </AuthProvider>
    <CssBaseline />
    <Toaster position="top-right" containerStyle={{ top: 80 }} />
  </ThemeProvider>
)


ServiceWorkerRegistration.register({
  onUpdate: async (registration: any) => {
    if (registration?.waiting) {
      await registration.unregister()
      registration.waiting.postMessage({ type: "SKIP_WAITING" })
      window.location.reload()
    }
  },
})
