import { createTheme } from '@mui/material';

import breakpoints from './breakpoints';
import components from './components';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  components,
  palette,
  shape: {
    borderRadius: 8,
  },
  shadows,
  typography,
  breakpoints,
});

export default theme;
