import Keycloak from 'keycloak-js';
import config from './config';

const keycloak = new Keycloak(config.keycloakConfig);

export const initialize = () => {
  return keycloak.init({
    onLoad: 'login-required',
  });
};

export const redirectToLogin = () => {
  keycloak.clearToken();
  keycloak.login();
};

export const logout = () => {
  return keycloak.logout();
};

export const getAccessToken = () => {
  return keycloak.token;
};

export default keycloak;
