import { lazy } from "react"
import { RouteObject } from "react-router-dom"

const MarketersPwaComponent = lazy(() => import("../MarketersPwa"))

const MarketersDashboardRoutes: RouteObject = {
  index: true,
  element: <MarketersPwaComponent />,
}

export default MarketersDashboardRoutes
