import { RouteObject } from "react-router-dom"
import MarketersDashboardRoutes from "../features/MarketersPwa/routes"
import PageGuard from "../guards/PageGuard"
import DashboardLayout from "../layouts/Dashboard/Dashboard"

const routesPages: RouteObject[] = [MarketersDashboardRoutes]

const Routes: RouteObject[] = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <PageGuard />,
        children: routesPages,
      },
    ],
  },
  {
    path: "*",
    element: <>404 Not Found</>,
  },
]

export default Routes
